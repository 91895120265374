// @flow

import { css } from 'styled-components';
import { Transition } from 'styles';

const hoverMultiple = (
  props: { prop: string, base: string | number, hover: string | number }[],
  noFocus: boolean = false,
  timeMs: string = Transition.TIME_DEFAULT,
  ease: string = Transition.EASE_DEFAULT
) => css`
  transition: ${props.reduce((css, { prop }, index) => css + `${prop} ${timeMs} ${ease}${index !== props.length - 1 ? ',' : ''}`, ``)};

  ${props.reduce(
    (css, { prop, base, hover }) =>
      css +
      `
    ${prop}: ${base};
    @media (any-hover: none) {
      &:hover {
        ${prop}: ${base};
      }
    
      &:active {
        ${prop}: ${hover};
      }
    }
    
    @media (any-hover: hover) {
      ${!noFocus ? `&:focus,` : ''} 
      &:hover {
        ${prop}: ${hover};
      }
    }
  `,
    ``
  )}
`;

export default hoverMultiple;
