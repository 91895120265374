// @flow

import { css } from 'styled-components';

type AlignOptions = 'center' | 'flex-start' | 'flex-end' | 'space-between' | 'space-around' | 'space-evenly' | 'stretch';

export const flex = (justify: AlignOptions = 'center', align: AlignOptions = 'center') => css`
  display: flex;
  justify-content: ${justify};
  align-items: ${align};
`;

export const inlineFlex = (justify: AlignOptions = 'center', align: AlignOptions = 'center') => css`
  display: inline-flex;
  justify-content: ${justify};
  align-items: ${align};
`;
