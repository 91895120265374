// @flow

import { css } from 'styled-components';

import * as Width from '../Width';

export const h1Default = css`
  font-weight: 700;
  line-height: 1.2;
  font-size: 2.8rem;
  letter-spacing: calc(-0.5em / 28);

  @media print, screen and (min-width: ${Width.BREAK_XL}px) {
    font-size: 4.2rem;
    line-height: 1.15;
  }
`;

export const h2Default = css`
  font-weight: 700;
  font-size: 2.8rem;
  line-height: 1.2;
  letter-spacing: calc(-0.5em / 28);

  @media print, screen and (min-width: ${Width.BREAK_MD}px) {
    font-size: 2.4rem;
  }

  @media print, screen and (min-width: ${Width.BREAK_XL}px) {
    font-size: 2.8rem;
  }
`;

export const h3Default = css`
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 1.3;
  letter-spacing: calc(-0.5em / 28);

  @media print, screen and (min-width: ${Width.BREAK_MD}px) {
    line-height: 1.2;
    font-size: 2.8rem;
  }
`;

export const h4Default = css`
  font-weight: 700;
  font-size: 2rem;
  line-height: 1.3;
  letter-spacing: calc(-0.5em / 28);
`;

export const h5Default = css`
  font-weight: 700;
  font-size: 1.5rem;
  letter-spacing: 0.02em;
  line-height: 1.5;
`;

export const h2842 = css`
  font-weight: 700;
  line-height: 1.2;
  font-size: 2.8rem;
  letter-spacing: calc(-0.5em / 28);

  @media print, screen and (min-width: ${Width.BREAK_MD}px) {
    font-size: 4.2rem;
    line-height: 1.15;
  }
`;

export const h282428 = css`
  font-size: 2.8rem;
  line-height: 1.2;
  letter-spacing: calc(-0.5em / 28);

  @media print, screen and (min-width: ${Width.BREAK_MD}px) {
    font-size: 2.4rem;
  }

  @media print, screen and (min-width: ${Width.BREAK_XL}px) {
    font-size: 2.8rem;
  }
`;

export const h282028 = css`
  font-size: 2.4rem;
  line-height: 1.2;
  letter-spacing: calc(-0.5em / 28);

  @media print, screen and (min-width: ${Width.BREAK_MD}px) {
    font-size: 2rem;
  }

  @media print, screen and (min-width: ${Width.BREAK_XL}px) {
    font-size: 2.8rem;
  }
`;

export const h242428 = css`
  font-size: 2.4rem;
  line-height: 1.15;
  letter-spacing: calc(-0.5em / 28);

  @media print, screen and (min-width: ${Width.BREAK_XL}px) {
    font-size: 2.8rem;
  }
`;

export const h203640 = css`
  font-size: 2rem;
  line-height: 1.3;
  letter-spacing: calc(-0.5em / 20);

  @media print, screen and (min-width: ${Width.BREAK_MD}px) {
    font-size: 3.6rem;
  }

  @media print, screen and (min-width: ${Width.BREAK_XL}px) {
    font-size: 4rem;
  }
`;

export const h201520 = css`
  font-size: 2rem;
  line-height: 1.3;
  letter-spacing: calc(-0.5em / 20);

  @media print, screen and (min-width: ${Width.BREAK_MD}px) {
    font-size: 1.5rem;
  }

  @media print, screen and (min-width: ${Width.BREAK_XL}px) {
    font-size: 2rem;
  }
`;

export const h20 = css`
  font-size: 2rem;
  line-height: 1.3;
  letter-spacing: calc(-0.5em / 20);
`;

export const h15 = css`
  line-height: 1.45;
  font-size: 1.5rem;
`;
