import * as colors from './Color';
import * as width from './Width';
import * as transition from './Transition';
import * as layout from './Layout';

export const Color = colors;
export const Width = width;
export const Transition = transition;
export const Layout = layout;

export { Fonts } from './Typography';
export { pDefault, p10, p1013, p12, p1214, p13, p1524, p18 } from './Typography/paragraph';
export {
  h1Default,
  h2Default,
  h3Default,
  h4Default,
  h5Default,
  h15,
  h20,
  h203640,
  h201520,
  h242428,
  h2842,
  h282428,
  h282028,
} from './Typography/header';
export { buttonDefault } from './Typography/button';
export { breakLine } from './break-line';
export { displayMobile, displayTablet, displayDesktop } from './display-device';
export { hover, hoverMultiple, hoverChild } from './Hover';
export { flex, inlineFlex } from './flex';
export { topMargin, bottomMargin, topBottomMargin } from './margin';
export { topPadding, bottomPadding } from './padding';
export { absolute } from './position';
export { maxWidthContainer } from './max-width-container';
export { widths } from './Width';
