import { createGlobalStyle } from 'styled-components';
import { Color } from 'styles';

const GlobalStyles = createGlobalStyle`
  *:focus {
    outline: none;
  }
  
  [data-whatintent='keyboard'] *:focus {
    outline: 1px dashed ${Color.BLURPLE_MOODY};
  }
  
  html {
    line-height: 1.15;
    box-sizing: border-box;
    font-size: 62.5%;
    overflow-y: scroll;
    -webkit-text-size-adjust: 100%;
    background: black;
  }
  
  *, *:before, *:after {
    box-sizing: inherit;
  }
  
  body {
    font-family: Times New Roman, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    width: 100%;
    overflow-x: hidden;
    word-wrap: break-word;
    font-kerning: normal;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
    background: black;
  }
  
  body, h1, h2, h3, h4, h5, h6, p, ol, ul, li, a, button {
    margin: 0;
    padding: 0;
    font-weight: normal;
  }
  
  h1, h2, h3, h4, h5, h6, p, ol, ul, li, a, button {
    text-rendering: optimizeLegibility;
    white-space: pre-line;
  }
  
  img {
    max-width: 100%;
    height: auto;
  }
  
  a {
    text-decoration: none;
  }
  
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner,
  button::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring,
  button:-moz-focusring {
    outline: 1px dotted ButtonText;
  }
  
  input, button, select {
    border: none;
    background: none;
    outline: none;
    cursor: pointer;
    border-radius: 0;
    -webkit-appearance: none;
  }
`;

export default GlobalStyles;
