// @flow

import { css } from 'styled-components';
import { Transition } from 'styles';

const hover = (
  prop: string,
  base: string | number,
  hover: string | number,
  noFocus: boolean = false,
  timeMs: string = Transition.TIME_DEFAULT,
  ease: string = Transition.EASE_DEFAULT
) => css`
  ${prop}: ${base};
  transition: ${prop} ${timeMs} ${ease};

  @media (any-hover: none) {
    &:hover {
      ${prop}: ${base};
    }

    &:active {
      ${prop}: ${hover};
    }
  }

  @media (any-hover: hover) {
    ${!noFocus && `&:focus,`}
    &:hover {
      ${prop}: ${hover};
    }
  }
`;

export default hover;
