// @flow

import { css } from 'styled-components';
import { BREAK_MD, BREAK_XL } from './Width';

export const topMargin = (mobile: number, tablet: number, desktop?: number) => css`
  margin-top: ${mobile}px;

  @media print, screen and (min-width: ${BREAK_MD}px) {
    margin-top: ${tablet}px;
  }

  ${desktop &&
    `
    @media print, screen and (min-width: ${BREAK_XL}px) {
      margin-top: ${desktop}px;
    }
  `}
`;

export const bottomMargin = (mobile: number, tablet: number, desktop?: number) => css`
  margin-bottom: ${mobile}px;

  @media print, screen and (min-width: ${BREAK_MD}px) {
    margin-bottom: ${tablet}px;
  }

  ${desktop &&
    `
    @media print, screen and (min-width: ${BREAK_XL}px) {
      margin-bottom: ${desktop}px;
    }
  `}
`;

export const topBottomMargin = (
  [mobileTop, mobileBottom]: number[],
  [tabletTop, tabletBottom]: number[],
  [desktopTop, desktopBottom]: number[]
) => css`
  margin-top: ${mobileTop}px;
  margin-bottom: ${mobileBottom}px;

  @media print, screen and (min-width: ${BREAK_MD}px) {
    margin-top: ${tabletTop}px;
    margin-bottom: ${tabletBottom}px;
  }

  @media print, screen and (min-width: ${BREAK_XL}px) {
    margin-top: ${desktopTop}px;
    margin-bottom: ${desktopBottom}px;
  }
`;
