// @flow

import React from 'react';
import Helmet from 'react-helmet';
import type { Node } from 'react';
import { Provider } from 'react-redux';

import UI from 'components/Global/UI';
import { NavContextProvider, PageContextProvider } from 'contexts';

type SiteContainerProps = {
  children: Node,
  location: Location,
};

const SiteContainer = ({ children, ...rest }: SiteContainerProps) => (
  <NavContextProvider>
    <PageContextProvider>
      <Helmet>
        <html lang="en" />
      </Helmet>
      <UI {...rest}>{children}</UI>
    </PageContextProvider>
  </NavContextProvider>
);

export default SiteContainer;
