// @flow

import { css } from 'styled-components';

import { Width } from 'styles';

export const breakLine = (small: boolean, medium: boolean, large: boolean = true) => css`
  white-space: ${small ? 'pre-line' : 'unset'};

  @media print, screen and (min-width: ${Width.BREAK_MD}px) {
    white-space: ${medium ? 'pre-line' : 'unset'};
  }

  @media print, screen and (min-width: ${Width.BREAK_XL}px) {
    white-space: ${large ? 'pre-line' : 'unset'};
  }
`;
