// @flow

import { css } from 'styled-components';

import * as Width from '../Width';

export const pDefault = css`
  font-weight: 400;
  font-size: 1.5rem;
  letter-spacing: 0.02em;
  line-height: 1.5;
`;

export const p10 = css`
  font-size: 1rem;
  line-height: calc(17 / 10);
  letter-spacing: 0.015em;
`;

export const p1013 = css`
  font-size: 1rem;
  line-height: 1.2;

  @media print, screen and (min-width: ${Width.BREAK_MD}px) {
    font-size: 1.3rem;
    line-height: calc(16 / 13);
  }
`;

export const p12 = css`
  font-size: 1.2rem;
  line-height: calc(17 / 10);
`;

export const p1214 = css`
  font-size: 1.2rem;
  line-height: calc(17 / 12);

  @media print, screen and (min-width: ${Width.BREAK_MD}px) {
    font-size: 1.4rem;
    line-height: calc(23 / 14);
  }
`;

export const p13 = css`
  font-size: 1.3rem;
  line-height: calc(16 / 13);
`;

export const p1524 = css`
  @media print, screen and (min-width: ${Width.BREAK_MD}px) {
    font-size: 2.4rem;
    line-height: 1.4;
  }
`;

export const p18 = css`
  font-size: 1.8rem;
`;
