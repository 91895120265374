// @flow

export const PRODUCT = {
  NONE: 'NONE',
  SLR: 'SLR',
  HELOC: 'HELOC',
  MORTGAGE_REFI: 'MORTGAGE_REFI',
};

export const TYPE = {
  DEFAULT: 'DEFAULT',
  AFFILIATE: 'AFFILIATE',
};
