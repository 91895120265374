// @flow

import React, { createContext, useContext, useEffect } from 'react';
import type { Node } from 'react';
import { useImmerReducer } from 'use-immer';

import { Actions as NavContextActions } from './actions';
import type { ActionsType } from './actions';
import { Detection } from 'Constants';
import { Width } from 'styles';
import { closeFullscreenOverlay, initFullscreenOverlay } from 'utils';

type State = {
  atTop: boolean,
  scrollingUp: boolean,
  showingMobileNav: boolean,
  renderMobile: boolean,
  renderDesktop: boolean,
  desktopWide: boolean,
};

const initialState: State = {
  atTop: true,
  scrollingUp: false,
  showingMobileNav: false,
  renderMobile: true,
  renderDesktop: true,
  desktopWide: true,
};

const BREAK_DESKTOP_WIDE = 1040;
let navWasClosed: boolean = false;

const reducer = (draft: State, action: ActionsType): void => {
  const { type, payload } = action;
  switch (type) {
    case NavContextActions.SET_AT_TOP:
      if (!draft.showingMobileNav) draft.atTop = payload;
      break;
    case NavContextActions.SET_SCROLLING_UP:
      if (!draft.showingMobileNav) draft.scrollingUp = payload;
      break;
    case NavContextActions.TOGGLE_MOBILE_NAV:
      draft.showingMobileNav = !draft.showingMobileNav;
      if (draft.showingMobileNav) initFullscreenOverlay();
      else {
        if (payload?.pageToTop) draft.atTop = true;
        navWasClosed = true;
        closeFullscreenOverlay(payload?.pageToTop);
      }
      break;
    case NavContextActions.PROVIDE_WINDOW_WIDTH:
      draft.renderMobile = payload > 0 && payload < Width.BREAK_MOBILE_NAV;
      draft.renderDesktop = payload > 0 && payload >= Width.BREAK_MOBILE_NAV;
      draft.desktopWide = payload >= BREAK_DESKTOP_WIDE;
      break;
  }
};

const setAtTop = (dispatch, payload) =>
  dispatch({
    type: NavContextActions.SET_AT_TOP,
    payload,
  });

const setScrollingUp = (dispatch, payload) =>
  dispatch({
    type: NavContextActions.SET_SCROLLING_UP,
    payload,
  });

const DispatchContext: any = createContext();
const StateContext: any = createContext();

const NavContextProvider = ({ children }: { children: Node }) => {
  const [state, dispatch] = useImmerReducer(reducer, initialState);
  useEffect(() => {
    if (Detection.IS_BROWSER) {
      let ticking: boolean = false;
      let prevY: number = window.scrollY || window.pageYOffset;
      let prevScrollingUp: boolean = false;
      setAtTop(dispatch, prevY === 0);
      window.addEventListener('scroll', () => {
        if (!ticking) {
          window.requestAnimationFrame(() => {
            const y: number = window.scrollY || window.pageYOffset;
            const isAtTop = y <= 0;
            const sameButWasScrollingUp = prevScrollingUp && y === prevY;
            let scrollingUp = !isAtTop && (sameButWasScrollingUp || y < prevY);
            setAtTop(dispatch, isAtTop);
            if (navWasClosed && y !== 0) {
              scrollingUp = true;
              navWasClosed = false;
            }
            setScrollingUp(dispatch, scrollingUp);
            prevScrollingUp = scrollingUp;
            prevY = y;
            ticking = false;
          });
        }
        ticking = true;
      });
    }
  }, [dispatch]);
  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
    </StateContext.Provider>
  );
};

type Dispatch = (action: ActionsType) => void;

const useNavDispatch = (): Dispatch => useContext(DispatchContext);
const useNavState = (): State => useContext(StateContext);

export { NavContextProvider, useNavDispatch, useNavState, NavContextActions };
