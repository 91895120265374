// @flow

import React, { useCallback } from 'react';
import type { Node } from 'react';
import ResizeDetector from 'react-resize-detector';

import GlobalStyles from 'global-styles';
import Body from 'components/Global/Body';
import { NavContextActions, useNavDispatch } from 'contexts';

type Props = {
  children: Node,
};

const UIContainer = ({ children }: Props) => {
  const dispatch = useNavDispatch();
  const handleWidth = useCallback(
    width =>
      dispatch({
        type: NavContextActions.PROVIDE_WINDOW_WIDTH,
        payload: width,
      }),
    [dispatch]
  );
  return (
    <>
      <ResizeDetector handleWidth onResize={handleWidth} />
      <GlobalStyles />
      <Body>{children}</Body>
    </>
  );
};

export default UIContainer;
