// @flow

import { css } from 'styled-components';
import { BREAK_MD, BREAK_XL } from './Width';

export const topPadding = (mobile: number, tablet: number, desktop?: number) => css`
  padding-top: ${mobile}px;

  @media print, screen and (min-width: ${BREAK_MD}px) {
    padding-top: ${tablet}px;
  }

  ${desktop &&
    `
    @media print, screen and (min-width: ${BREAK_XL}px) {
      padding-top: ${desktop}px;
    }
  `}
`;

export const bottomPadding = (mobile: number, tablet: number, desktop?: number) => css`
  padding-bottom: ${mobile}px;

  @media print, screen and (min-width: ${BREAK_MD}px) {
    padding-bottom: ${tablet}px;
  }

  ${desktop &&
    `
    @media print, screen and (min-width: ${BREAK_XL}px) {
      padding-bottom: ${desktop}px;
    }
  `}
`;
