// @flow

import { css } from 'styled-components';

import { Fonts } from 'styles';

export const buttonDefault = css`
  ${Fonts.GOTHIC};
  font-weight: 700;
  font-size: 1.2rem;
  letter-spacing: 0.02em;
  line-height: 1;
`;
