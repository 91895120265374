// @flow

import { css } from 'styled-components';

export const absolute = (
  top: string | number = 0,
  right: string | number = 0,
  bottom: string | number = 0,
  left: string | number = 0
) => css`
  position: absolute;
  top: ${top};
  right: ${right};
  bottom: ${bottom};
  left: ${left};
`;
