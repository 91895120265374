export const WHITE = '#FFFFFF';
export const GREY_FOUR = '#D2D2D2';
export const GREY_LIGHT = '#8D8D8D';
export const GREY_MEDIUM = '#4D4D4D';
export const PURPLE_MAIN = '#24007E';
export const PURPLE_FIGURE = '#42368E';
export const BLURPLE_LIGHT = '#F3F4F9';
export const BLURPLE_MOODY = '#190050';
export const BLACK = '#000000';
export const BLACK_SOUL = '#2D2D2D';
export const YELLOW_MELLOW = '#FEC82A';
