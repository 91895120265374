// @flow

import { css } from 'styled-components';
import { Transition } from 'styles';

const hoverChild = (
  selector: string,
  prop: string,
  base: string | number,
  hover: string | number,
  noFocus: boolean = false,
  timeMs: string = Transition.TIME_DEFAULT,
  ease: string = Transition.EASE_DEFAULT
) => css`
  ${selector} {
    ${prop}: ${base};
    transition: ${prop} ${timeMs} ${ease};
  }

  @media (any-hover: none) {
    &:hover {
      ${selector} {
        ${prop}: ${base};
      }
    }

    &:active {
      ${selector} {
        ${prop}: ${hover};
      }
    }
  }

  @media (any-hover: hover) {
    ${!noFocus && `&:focus,`}
    &:hover {
      ${selector} {
        ${prop}: ${hover};
      }
    }
  }
`;

export default hoverChild;
