// @flow

export const Actions = {
  SET_AT_TOP: 'SET_AT_TOP',
  SET_SCROLLING_UP: 'SET_SCROLLING_UP',
  PROVIDE_WINDOW_WIDTH: 'PROVIDE_WINDOW_WIDTH',
  TOGGLE_MOBILE_NAV: 'TOGGLE_MOBILE_NAV',
};

export type ActionsType =
  | {
      type: Actions.SET_AT_TOP,
      payload: boolean,
    }
  | {
      type: Actions.SET_SCROLLING_UP,
      payload: boolean,
    }
  | {
      type: Actions.PROVIDE_WINDOW_WIDTH,
      payload: number,
    }
  | {
      type: Actions.TOGGLE_MOBILE_NAV,
      payload?: {
        pageToTop?: boolean,
      },
    };
