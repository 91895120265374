// @flow

import { BODY_ID } from 'components/Global/Body';
import { Detection } from 'Constants';

let cachedY: number = 0;
let bodyRef: HTMLElement;

export const initFullscreenOverlay = () => {
  if (!bodyRef) bodyRef = document.getElementById(BODY_ID);
  cachedY = window.scrollY || window.pageYOffset;
  toggleLock(true);
  if (bodyRef) bodyRef.style.transform = `translateY(-${cachedY}px)`;
};

export const closeFullscreenOverlay = (pageToTop = false) => {
  if (!bodyRef) bodyRef = document.getElementById(BODY_ID);
  toggleLock(false);
  if (bodyRef) bodyRef.style.transform = 'unset';
  if (pageToTop) {
    cachedY = 0;
  } else if (cachedY !== 0) {
    setTimeout(() => {
      window.scrollTo(0, cachedY);
      cachedY = 0;
    }, 1);
  }
};

export const toggleLock = (lock: boolean) => {
  if (Detection.IS_BROWSER) {
    const { body, documentElement } = document;
    body.style.overflowY = lock ? 'hidden' : '';
    body.style.height = lock ? '100%' : '';
    body.style.width = lock ? '100%' : '';
    body.style.position = lock ? 'fixed' : '';
    documentElement.style.overflowY = lock ? 'hidden' : '';
    documentElement.style.height = lock ? '100%' : '';
    documentElement.style.position = lock ? 'fixed' : '';
    documentElement.style.width = lock ? '100%' : '';
  }
};
