// @flow

import React from 'react';
// eslint-disable-next-line no-duplicate-imports
import type { Node } from 'react';

import './polyfills';
import 'what-input';
import SiteContainer from 'components/Global/SiteContainer';
import { Detection } from 'Constants';
import { registerPassiveEvent } from 'utils';

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (prevLocation) {
  }
};

export const wrapPageElement = ({ element, props }: { element: Node, props: any }) => (
  <SiteContainer {...props}>{element}</SiteContainer>
);
