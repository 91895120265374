// @flow

import { css } from 'styled-components';

export const BREAK_XS = 340;
export const BREAK_SM = 500;
export const BREAK_MD = 768;
export const XL = 1200;

export const BREAK_MOBILE_NAV = 740;

export const MARGIN_SMALL = 27;
export const MARGIN_MEDIUM = 42;

export const BREAK_XL = XL + MARGIN_MEDIUM * 2;

export const widths = (mobile: number | string, tablet?: number, desktop?: number) => css`
  width: ${typeof mobile === 'string' ? mobile : `${mobile}px`};

  ${tablet &&
    `@media print, screen and (min-width: ${BREAK_MD}px) {
      width: ${tablet}px;
    }`};

  ${desktop &&
    `@media print, screen and (min-width: ${BREAK_XL}px) {
      width: ${desktop}px;
    }`};
`;
