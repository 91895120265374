// @flow

import { css } from 'styled-components';

import * as Width from './Width';

export const displayMobile = css`
  @media print, screen and (min-width: ${Width.BREAK_MD}px) {
    display: none;
  }
`;

export const displayTablet = css`
  display: none;
  @media print, screen and (min-width: ${Width.BREAK_MD}px) {
    display: block;
  }
  @media print, screen and (min-width: ${Width.BREAK_XL}px) {
    display: none;
  }
`;

export const displayDesktop = css`
  display: none;
  @media print, screen and (min-width: ${Width.BREAK_XL}px) {
    display: block;
  }
`;
