// @flow

import React, { createContext, useContext, useState, useCallback } from 'react';
import type { Node } from 'react';

import { PRODUCT, TYPE } from 'Constants/PageType';
import { PageType } from 'Constants';

export type PageConfig = {
  product: $Keys<typeof PRODUCT>[],
  type: $Keys<typeof TYPE>,
};

type PageConfigAction = {
  product: $Keys<typeof PRODUCT> | $Keys<typeof PRODUCT>[],
  type: $Keys<typeof TYPE>,
};

type LegalItem = Node | string;

export type FooterLegal = {
  companyName: LegalItem,
  top: LegalItem[],
  numbered: LegalItem[],
  bottom: LegalItem[],
  overwrite: boolean,
};

type FooterLegalAction = {
  companyName?: LegalItem,
  top?: LegalItem | LegalItem[],
  numbered?: LegalItem | LegalItem[],
  bottom?: LegalItem | LegalItem[],
  overwrite?: boolean,
};

type State = {
  page: PageConfig,
  footerLegal: FooterLegal,
};

const initialState: State = {
  page: {
    product: [PageType.PRODUCT.NONE],
    type: PageType.TYPE.DEFAULT,
  },
  footerLegal: {
    companyName: null,
    top: [],
    numbered: [],
    bottom: [],
    overwrite: false,
  },
};

const SetterContext: any = createContext();
const StateContext: any = createContext();

const PageContextProvider = ({ children }: { children: Node }) => {
  const [state, setState] = useState(initialState);
  const setPageState = useCallback(
    (data: { page: PageConfigAction, footerLegal: FooterLegalAction }) => {
      const {
        page: { type, product },
        footerLegal: { companyName = null, top = [], numbered = [], bottom = [], overwrite = false },
      } = data;
      let page: any;
      if (Array.isArray(product)) {
        if (product.includes(PageType.PRODUCT.NONE)) {
          throw new Error(`PageConfig.product cannot be an array with ${product.join(' & ')}`);
        } else page = { type, product };
      } else {
        page = {
          type,
          product: [product],
        };
      }
      setState({
        page,
        footerLegal: {
          companyName,
          top: Array.isArray(top) ? top : [top],
          numbered: Array.isArray(numbered) ? numbered : [numbered],
          bottom: Array.isArray(bottom) ? bottom : [bottom],
          overwrite,
        },
      });
    },
    [setState]
  );
  return (
    <StateContext.Provider value={state}>
      <SetterContext.Provider value={setPageState}>{children}</SetterContext.Provider>
    </StateContext.Provider>
  );
};

const useSetPageState = () => useContext(SetterContext);
const usePageState = (): State => useContext(StateContext);

export { PageContextProvider, useSetPageState, usePageState };
