// @flow

const ENV_DEV: string = 'development';
export const IS_DEV: boolean = process.env.GATSBY_ENV === ENV_DEV;
export const IS_BROWSER: boolean = typeof window !== 'undefined';
export const IS_LOCALHOST: boolean = IS_BROWSER && window.location.hostname === 'localhost';
export const HAS_LOCAL_STORAGE: boolean =
  IS_BROWSER &&
  (() => {
    const mod = 'arbitrary_value';
    try {
      localStorage.setItem(mod, mod);
      localStorage.removeItem(mod);
      return true;
    } catch (e) {
      return false;
    }
  })();
