import * as detection from './Detection';
import * as pageType from './PageType';

export const Detection = detection;
export const PageType = pageType;

export { IS_DEV, IS_BROWSER, IS_LOCALHOST, HAS_LOCAL_STORAGE } from './Detection';
export { REGISTRATION, ONBOARDING_ROOT } from './Redirect';
export { PROMO_CODE_KEYS, LENDING_TREE_KEYS } from './SessionStorage';
export { FOOTER_DISCLAIMER_SECTIONS } from './Footer';
