// @flow

import React from 'react';
import type { Node } from 'react';
import styled from 'styled-components';

import { flex } from 'styles';

export const BODY_ID: string = 'body';

const Wrapper = styled.div`
  min-height: 100vh;
  ${flex()};
  flex-direction: column;
`;

const Body = ({ children }: { children: Node }) => <Wrapper id={BODY_ID}>{children}</Wrapper>;

export default Body;
